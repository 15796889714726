const qs = require('qs');
const url = require('url');
const assignIn = require('lodash.assignin');
const util = require('../../util');
const ajax = require('../../ajax');

let wistiaThumbInit = false;

const zoomMediaQuery = matchMedia('(min-width: 768px)');
let productPage = false;

if (window.pageContext.ns === 'product') {
    productPage = true;
}

/**
 * @description Enables the zoom viewer on the product detail page
 * @param zmq {Media Query List}
 */
function loadZoom(zmq) {
    const $imgZoom = $('#pdpMain .slick-current .main-image');
    const zoomImageCurrent = $('#pdpMain .slick-current .main-image .zoomImg');
    const attrImage = $('#pdpMain .slick-current .main-image img').data('lgimg');

    if (zoomImageCurrent.length > 0) {
        $(zoomImageCurrent).each((index, element) => {
            $(element).remove();
        });
    }

    if (!zmq) {
        // eslint-disable-next-line
        zmq = zoomMediaQuery;
    }

    if ($imgZoom.length === 0 || !productPage || util.isMobile() || !zoomMediaQuery.matches) {
        // remove zoom
        $imgZoom.trigger('zoom.destroy');
        return;
    }

    const hiresUrl = $imgZoom.attr('href');
    if (hiresUrl && hiresUrl !== 'null' && hiresUrl.indexOf('noimagelarge') === -1 && zoomMediaQuery.matches && attrImage.zoom !== 'false') {
        $imgZoom.zoom({
            url: hiresUrl,
            on: 'grab',
        });
    }

    $('body').on('mouseenter mouseup', '#pdpMain .product-image-container .slick-current', () => {
        $('.zoom-text').show();
    });

    $('body').on('mouseleave mousedown', '#pdpMain .product-image-container .slick-current', () => {
        $('.zoom-text').hide();
    });

    $('body').bind('mousemove', '#pdpMain .product-image-container .slick-current', (e) => {
        $('.zoom-text').offset({
            left: e.pageX + 5,
            top: e.pageY + 20,
        });
    });
}

zoomMediaQuery.addListener(loadZoom);

/**
 * @function
 * @desc Updates the Pinterest button URL to the newly selected main image
 * @param imageUrl {string} URL used to update the Pinterest button
 */
function updatePinButton(imageUrl) {
    const pinButton = document.querySelector('.share-icon[data-share=pinterest]');
    if (!pinButton) {
        return;
    }
    let newUrl = imageUrl;
    if (!imageUrl) {
        newUrl = document.querySelector('#pdpMain .primary-image').getAttribute('src');
    }
    const href = url.parse(pinButton.href);
    const query = qs.parse(href.query);
    query.media = url.resolve(window.location.href, newUrl);
    query.url = window.location.href;
    const newHref = url.format(assignIn({}, href, {
        query, // query is only used if search is absent
        search: qs.stringify(query),
    }));
    pinButton.href = newHref;
}

/**
 * @function
 * @description Replaces the images in the image container, for eg. when a different color was clicked.
 */
function replaceImages() {
    const $newImages = $('.update-images');
    const $imageContainer = $('#pdpMain .product-image-container');
    if ($newImages.length === 0) { return; }

    $imageContainer.html($newImages.html());
    $newImages.remove();
    loadZoom();
    picturefill();
}

/**
 * Initialize Wistia video player and set the thumbnail image.  This does
 * not need to be done inside window.wistiaInit; doing it immediately
 * so we are not depending on timers.  We'll use the wistiaThumbInit flag
 * to make sure it only executes once.
 *
 * There is a lot of crazy things happening in the Wistia library involving
 * timers and asynchronous functions, and depending on when this code executes,
 * certain elements could be in unpredictable states.  We'll change the thumbnail image
 * if possible, but if it isn't in the DOM at this point, use mutation observers
 * to detect when it is added and set it then.
 */
function wistiaThumbmail() {
    if (wistiaThumbInit) {
        return;
    }

    const wistiaThumb = $('#pdpMain').find('.wistia_thumb');

    if (wistiaThumb.length > 0) {
        wistiaThumbInit = true;

        const videoKey = $('#pdpMain').find('.wistia_thumb').data('apivideokey');
        const regex = /\w+$/;
        const apiKey = regex.exec(videoKey);

        /* eslint-disable no-underscore-dangle */
        window._wq = window._wq || [];
        window._wq.push({
            id: apiKey,
            options: {
                videoFoam: {
                    maxWidth: 768,
                },
            },
        });
        /* eslint-enable no-underscore-dangle */

        wistiaThumb.addClass('active');

        if (productPage && videoKey) {
            ajax.getJson({
                url: videoKey,
                callback(data) {
                    if (!data || !data.thumbnail_url) {
                        return false;
                    }

                    const thumbnailUrl = `${data.thumbnail_url.substring(0, data.thumbnail_url.indexOf('?'))}?image_crop_resized=130x95`;
                    if (wistiaThumb.find('img').length > 0) {
                        wistiaThumb.find('img').attr('src', thumbnailUrl);
                        return true;
                    }

                    // If the img element was not found in wistiaThumb, it is because the Wistia library
                    // is currently modifying the wistiaThumb element asynchronously.  Watch the element
                    // with a mutation observer, and change the src to thumbnailUrl when the img is added.
                    const wistiaObserver = new MutationObserver(() => {
                        const wistiaThumbImg = wistiaThumb.find('img');
                        if (wistiaThumbImg.length > 0) {
                            wistiaThumbImg.attr('src', thumbnailUrl);
                            wistiaObserver.disconnect();
                        }
                    });

                    wistiaObserver.observe(wistiaThumb.get(0), {childList: true, subtree: true});
                    return true;
                },
            });
        }
    }
}

/**
 * @function
 * @description Change main image on thumb hover.
 */
function mainImageHoverChange() {
    if (!util.isMobile()) {
        const currentMainImage = $('.product-primary-image .slick-current a .primary-image');
        const currentMainImageUrl = currentMainImage.attr('src');
        $('.product-thumbnails-list li').each((index, element) => {
            $(element).hover(
                (e) => {
                    currentMainImage.attr('src', $(e.currentTarget).find('.productthumbnail').data('lgimg').url);
                },
                () => {
                    currentMainImage.attr('src', currentMainImageUrl);
                },
            );
        });
    }
}

/**
 * @description Sets the main image attributes and the href for the surrounding <a> tag
 * @param {Object} atts Object with url, alt, title and hires properties
 */
function setMainImage(atts) {
    $('#pdpMain .slick-current .primary-image').attr({
        src: atts.url,
        alt: atts.alt,
        title: atts.title,
    });

    updatePinButton(atts.url);

    if (productPage && !util.isMobile()) {
        $('#pdpMain .slick-current .main-image').attr('href', atts.hires);
    }

    mainImageHoverChange();
    loadZoom();
}

/**
 * @function
 * @description Thumbnail slider for different viewports.
 */
function heroCarousel() {
    if ($('#pdpMain #thumbnails').length > 0) {
        const primaryImageContainer = $('.product-primary-image');
        const thumbImages = $('#thumbnails .slick-slider img');
        const allImages = primaryImageContainer.find('img').add(thumbImages);

        const handleImageLoad = (resolve) => {
            let imagesLoaded = 0;
            allImages.each((index, image) => {
                if (image.complete && image.naturalHeight !== 0) {
                    imagesLoaded += 1;
                    if (imagesLoaded === allImages.length) {
                        resolve();
                    }
                } else {
                    $(image).on('load', () => {
                        imagesLoaded += 1;
                        if (imagesLoaded === allImages.length) {
                            resolve();
                        }
                    });
                }
            });
        };

        const imagesLoadedPromise = new Promise((resolve) => {
            handleImageLoad(resolve);
        });

        imagesLoadedPromise.then(() => {
            // Initialize Slick sliders after all images have loaded
            primaryImageContainer.on('init', (e) => {
                $(e.currentTarget).find('.slick-arrow').removeAttr('style');
                const currentImg = $(e.currentTarget).find('.slick-current img');
                if (currentImg.data('lgimg')) {
                    setMainImage(currentImg.data('lgimg'));
                }
            }).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                fade: true,
                speed: 300,
                dots: true,
                asNavFor: '#thumbnails .slick-slider',
                responsive: [
                    {
                        breakpoint: util.getViewports('md'),
                        settings: {
                            fade: false,
                            arrows: false,
                        },
                    },
                ],
            }).on('afterChange', (e) => {
                const currentImg = $(e.currentTarget).find('.slick-current img');
                if (currentImg.data('lgimg')) {
                    setMainImage(currentImg.data('lgimg'));
                }
            });

            const thumbCarousel = $('#thumbnails .slick-slider');
            const thumbsToShow = 4;
            thumbCarousel.on('init', () => {
                if (thumbCarousel.find('.thumb').length > thumbsToShow) {
                    thumbCarousel.addClass('with-nav');
                }
            }).slick({
                speed: 300,
                slidesToShow: thumbsToShow,
                slidesToScroll: 1,
                vertical: true,
                infinite: false,
                centerMode: true,
                asNavFor: '.product-primary-image',
                focusOnSelect: true,
                slide: '.thumb',
            });
        });
    }
}

/**
 * @description by default, this function sets up zoom and event handler for thumbnail click
 * */
module.exports = function setupZoom() {
    if (!productPage || util.isMobile()) {
        $('#pdpMain .main-image').removeAttr('href');
    }

    updatePinButton();
    heroCarousel();
    loadZoom();
    mainImageHoverChange();
    wistiaThumbmail();

    // handle product thumbnail click event
    $('#pdpMain').on('click', '.productthumbnail', (e) => {
        // switch indicator
        $(e.currentTarget).closest('.product-thumbnails').find('.thumb.selected').removeClass('selected');
        $(e.currentTarget).closest('.thumb').addClass('selected');

        const zoomImageCurrent = $('#pdpMain .main-image .zoomImg');

        if (zoomImageCurrent.length > 0) {
            $(zoomImageCurrent).each((index, element) => {
                $(element).remove();
            });
        }

        setMainImage($(e.currentTarget).data('lgimg'));
    });

    /**
     * @listener
     * @description Listens for the click event on the main product image and prevents the link from being followed
     */
    $('#pdpMain').on('click', '.main-image', (e) => {
        e.preventDefault();
    });
};
module.exports.loadZoom = loadZoom;
module.exports.setMainImage = setMainImage;
module.exports.replaceImages = replaceImages;
module.exports.heroCarousel = heroCarousel;
module.exports.wistiaThumbmail = wistiaThumbmail;
